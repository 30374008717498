body {
  background-color: rgba(0, 0, 0, 1);
}
.App {
  height: 100vh;
  width: 100vw;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
div {
  color: white;
  box-sizing: border-box;
  margin: 0;
  line-height: 1.5rem;
}

h1,
h2 {
  margin: 0;
  font-family: sans-serif;
}

.App__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  max-height: 100px;
  margin: 0px auto;
  padding: 20px;
}

.constrain {
  display: flex;
  max-width: 1200px;
  margin: 0px auto;
  padding: 20px 50px;
  /* width: 100%; */
}

.constrain > div {
  padding-right: 10px;
  padding-left: 10px;
}

.header__logo {
  max-width: 20%;
}

.header__logo img {
  width: 100%;
}

.header__nav-container {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

a {
  text-decoration: none;
  color: white;
}

.header__nav-container a {
  text-decoration: none;
  color: white;
  margin: 0 20px;
  font-weight: 700;
}

main {
  /* width: 100%; */
  min-height: 70vh;
}

.slider-frame ul.slider-list {
  max-height: 450px;
  /* min-width: auto; */
}

.slider-frame img {
  height: fit-content;
}

.home__info {
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 50px 0;
}

.home__info_details,
.home__info_contact {
  display: flex;
  flex-direction: column;
}

.home__info_details,
.home__info_contact {
  /* width: 50%; */
  display: flex;
}

.home__info p,
.home__info_contact {
  color: black;
}

.home__info_contact div {
  margin: 10px 0;
}

.home__info_social-link {
  margin: 5px;
}

.home__info_title-text {
  color: #ff9933;
  font-size: 3rem;
  text-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3);
}

.home__info_details-text {
  margin-top: 20px;
}

.home__info_phone-number {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/tent-renters.appspot.com/o/www%2Fphonenumber.gif?alt=media&token=b883fe44-e227-4af9-9136-30e3ba79210b");
  width: 100%;
  height: 110px;
  background-repeat: no-repeat;
  background-size: contain;
}

.home__info_email {
  color: black;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
}

.home__info_social {
  display: flex;
  justify-content: center;
}

.photos {
  max-width: 1200px;
  margin: 0 auto;
}

.footer {
  /* min-height: 250px; */
  margin: 55px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer-logo,
.footer-info {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 15px;
}

.footer-logo__wordmark {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/tent-renters.appspot.com/o/www%2Fwordmark.png?alt=media&token=6c3e8666-fe94-4441-8cd6-7e83b9777604");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
}

.footer-logo__contact {
  display: flex;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
}

.footer-logo__phone {
  min-width: 158px;
}
.footer-logo__phone,
.footer-logo__email {
  margin: 3px 5px;
  font-size: 1.3rem;
}

.hover {
  transition-property: color;
  transition-duration: 0.3s;
}

.hover:hover {
  color: #f7931f;
  cursor: pointer;
}

.footer-info__title {
  font-size: 1.3rem;
  font-weight: 900;
  margin: 0 0 10px 0;
}

/* .App__header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

@media (max-width: 700px) {
  .constrain {
    flex-direction: column;
    padding: 20px;
  }
  .constrain > div {
    padding-bottom: 20px;
  }
}

@media (max-width: 385px) {
  .constrain {
    max-width: 100%;
    padding: 10px;
  }
  .home__info_title-text {
    font-size: 2.3rem;
  }
  .home__info_email,
  .footer-logo__phone,
  .footer-logo__email {
    font-size: 1rem;
  }
  nav ul {
    margin: 0;
    padding: 0;
  }
  .header__nav-container a {
    margin: 0 5px;
  }
}
